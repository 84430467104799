const config = {
  enedisApi: {
    baseUrl: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_URI}`,
  },
  businessData: {
    baseUrl:
      process.env.VUE_APP_BUSINESS_DATA_BASE_URL ||
      'http://api-service.business-data-beta',
    apiKey:
      process.env.VUE_APP_BUSINESS_DATA_API_KEY || '6Ymi9bohizaagk79c5sDxRH5j9HDFX86',
  },
  salesforceGateway: {
    baseUrl:
      process.env.VUE_APP_SALESFORCE_GATEWAY_BASE_URL ||
      'https://sfdc-gateway.collectifenergie.com',
    apiKey:
      process.env.VUE_APP_SALESFORCE_GATEWAY_API_KEY ||
      's66CaWhhxDnSBi5kE6plIx8Pz1dgOiobPiPDqC63',
  },
  matomo: {
    host: process.env.VUE_APP_MATOMO_HOST || 'https://matomo.collectifenergie.com',
    siteId: process.env.VUE_APP_MATOMO_ID || '1',
  },
  stockTracker: {
    baseUrl:
      process.env.VUE_APP_STOCK_TRACKER_BASE_URL ||
      '',
    apiKey:
      process.env.VUE_APP_STOCK_TRACKER_ADMIN_API_KEY ||
      '',
  },
};

module.exports = config;
export default config;
